<template>
  <a-modal
    title="发起审批"
    :visible="visible"
    :footer="null"
    width="730px"
    dialogClass="schedule-related"
    @cancel="onCancel(0)"
  >
    <div v-if="formData">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item :label="formData.fields[0].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[0].id, { rules: [{ required: true, message: '请选择问题!' }] }]"
            placeholder="请选择问题"
            disabled
            @change="checkQuestion"
          >
            <a-select-option v-for="(item, index) in questionArray" :key="index" :value="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[1].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[1].id, { rules: [{ required: true, message: '请输入科目!' }] }]"
            placeholder="请输入科目"
            disabled
            @change="checkRequired"
          >
            <a-select-option v-for="(item, index) in courseInfoArray" :key="index" :value="item.uuid">
              {{ item.scheduleName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[2].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[2].id, { rules: [{ required: true, message: '请输入学生姓名!' }] }]"
            placeholder="请输入学生姓名"
            disabled
            @change="checkRequired"
          >
            <a-select-option v-for="(item, index) in courseInfoArray" :key="index" :value="item.uuid">
              {{ item.scheduleName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[3].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[3].id, { rules: [{ required: true, message: '请输入学生学号!' }] }]"
            placeholder="请输入学生学号"
            disabled
            @change="checkRequired"
          >
            <a-select-option v-for="(item, index) in courseInfoArray" :key="index" :value="item.uuid">
              {{ item.scheduleName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[4].name" :wrapper-col="{ span: 18 }">
          <a-date-picker
            size="large"
            style="width: 100%"
            format="YYYY-MM-DD HH:mm"
            placeholder="请选择时间（北京时间）"
            :disabled-date="disabledDate"
            :show-time="{ defaultValue: moment('00:00', 'HH:mm') }"
            v-decorator="[formData.fields[4].id, config]"
            @change="checkRequired"
          />
        </a-form-item>
        <a-form-item :label="formData.fields[5].name">
          <a-textarea
            v-decorator="[formData.fields[5].id, { rules: [{ required: true, message: '审批原因!' }] }]"
            placeholder="审批原因"
            :auto-size="{ minRows: 3 }"
            @change="checkRequired"
          />
        </a-form-item>
        <!-- <a-form-item :label="formData.fields[6].name">
          <a-textarea
            v-decorator="[formData.fields[6].id, {}]"
            placeholder="请详细描述补充说明"
            :auto-size="{ minRows: 3 }"
            @change="checkRequired"
          />
        </a-form-item> -->
        <a-form-item :label="formData.fields[6].name">
          <a-upload
            action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
          >
            <div v-if="fileList.length < 6">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <Handler v-if="assignees && isAssignees" :assignees="assignees" />
        <a-form-item :wrapper-col="{ span: 12, offset: 12 }" style="margin-bottom: 0">
          <a-button @click="onCancel(0)"> 取消 </a-button>
          <a-button type="primary" @click="handleSubmit" :disabled="noAssigness" class="feedBackbtn"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import Handler from '@/components/WKHandler/index.vue';
import { imgpostUrl } from '@/api/register';
import { initFormResign, initOptions, initAssignees, getUploadOssToken, createTakeOverForm } from '@/api/headTeacher';
export default {
  components: { Handler },
  props: {
    // scheduleName: {
    //   type: String,
    //   default: '',
    // },
    currentViewData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // uuid: storage.get(UUID),
      userInfo: storage.get('userInfo'),
      // teacherName: storage.get(TEACHERINFO).teacherName,
      visible: true,
      form: this.$form.createForm(this),
      formData: null,
      questionArray: [{ name: '【学生补升】超过有效期' }],
      config: {
        rules: [{ type: 'object', required: true, message: '请选择日期(北京时间)!' }],
      },
      fileList: [],
      assignees: null,
      images: [],
      imgInfo: null,
      isAssignees: false,
      courseInfoArray: [],
      studentsAray: [],
      processName: '',
      options: null,
      noAssigness: false,
      classArray: [],
      pageNum: 1,
      changeCourseId: '',
      courseId: '',
      newStudents: [],
    };
  },
  mounted() {
    this.initForm();
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current < moment().subtract(1, 'days');
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // onChangeClass(value) {
    //   this.checkRequired();
    //   if (value.length === 0) {
    //     this.onSeachClass();
    //   }
    //   this.courseId = value;
    //   this.form.setFieldsValue({ courseInfo: undefined });
    //   this.form.setFieldsValue({ studentId: undefined });
    //   this.onSeachCourse();
    // },
    // onSeachClassFocus() {
    //   this.onSeachClass();
    // },
    // onSeachClass(value) {
    //   this.$post(`/api/teacher/work_flow/form_class/search/${this.pageNum}`, {
    //     teacherId: this.uuid,
    //     className: value,
    //   }).then((res) => {
    //     if (this.courseId) {
    //       this.form.setFieldsValue({ classId: this.courseId });
    //       this.checkRequired();
    //     }
    //     this.classArray = res.data.content.content;
    //   });
    // },
    // onChangeCourse(value) {
    //   this.changeCourseId = value;
    //   this.checkRequired();
    //   if (value.length === 0) {
    //     this.onSeachCourse();
    //   }
    //   this.courseInfoArray.forEach((item) => {
    //     if (item.uuid === value) {
    //       this.studentsAray = [];
    //       this.newStudents = item.students;
    //       item.students.forEach((k) => {
    //         this.studentsAray.push({
    //           studentName: `${k.name}(${k.code})`,
    //           id: k.id,
    //         });
    //       });
    //     }
    //   });
    //   this.form.setFieldsValue({ studentId: undefined });
    // },
    // onSeachCourse(value) {
    //   this.$post(`/api/teacher/work_flow/course_schedule/search/${this.pageNum}`, {
    //     teacherId: this.uuid,
    //     scheduleName: value,
    //     formClassId: this.courseId,
    //   }).then((res) => {
    //     this.courseInfoArray = res.data.content.content;
    //     this.checkRequired();
    //   });
    // },
    // initStudent() {
    //   const students = JSON.parse(JSON.stringify(this.scheduleData.students));
    //   students.forEach((item) => {
    //     this.studentsAray.push({
    //       studentName: `${item.name}(${item.code})`,
    //       id: item.id,
    //     });
    //   });
    // },
    // filterOption(input, option) {
    //   return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    // },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.url,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    checkQuestion(value) {
      this.processName = value;
    },
    // checkRequiredStudent(data) {
    //   this.checkRequired();
    //   this.initAssigness(data);
    // },
    initAssigness(studentId) {
      // this.processName = value;
      const data = {
        accountIds: [],
        businessKey: 'promote-expired-form',
        processKey: 'promote-expired',
      };
      // const accountArray = this.newStudents.length > 0 ? this.newStudents : this.scheduleData.students;
      // accountArray.forEach((item) => {
      //   if (item.id === studentId && item.classAdmin && item.classAdmin.CHINESE) {
      //     data.accountIds.push(item.classAdmin.CHINESE.accountId);
      //   }
      // });
      this.assignees = null;
      initAssignees(data)
        .then((res) => {
          this.noAssigness = false;
          const assignees = res.data.content;
          assignees.copyAccounts.accounts = assignees.copyAccounts.accounts.map((item) => {
            const roleStr = [];
            item.roles.forEach((i) => {
              roleStr.push(this.initOptionsType(i, this.options.userRole));
            });
            return {
              ...item,
              roleStr,
            };
          });
          assignees.dealAccounts = assignees.dealAccounts.map((item) => {
            const roleStr = [];
            // eslint-disable-next-line no-param-reassign
            item.accounts = item.accounts.map((i) => {
              const accountRole = [];
              // roleStr.push(this.initOptionsType(i, this.options.userRole));
              i.roles.forEach((k) => {
                roleStr.push(this.initOptionsType(k, this.options.userRole));
              });
              return {
                ...i,
                roleStr: accountRole,
              };
            });
            return {
              ...item,
              roleStr,
            };
          });
          this.assignees = assignees;
        })
        .catch(() => {
          // this.noAssigness = true;
        });
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    initForm() {
      initFormResign({ type: 'promote-expired-form' }).then((res) => {
        this.formData = res.data.content;
        this.initFormValue();
        //获取处理人
        this.initAssigness();
      });
      initOptions().then((res) => {
        this.options = res.data.content;
      });
      // this.$fetch('/api/teacher/work_flow/form/course-process-form').then((res) => {
      //   this.formData = res.data.content;
      //   if (this.scheduleName) {
      //     this.$nextTick(() => {
      //       this.courseInfoArray.push({
      //         scheduleName: this.scheduleName,
      //         uuid: this.reSubmitData?.processVariables.courseScheduleId || this.$route.query,
      //       });
      //       if (this.reSubmitData) {
      //         this.resubmitHandle();
      //         this.onSeachClass();
      //         this.onSeachCourse();
      //       } else {
      //         this.form.setFieldsValue({ courseInfo: this.$route.query });
      //       }
      //     });
      //   }
      // });
      // this.$fetch('/api/service_center/work_studio/options').then((res) => {
      //   this.options = res.data.content;
      // });
    },
    initFormValue() {
      this.processName = '【学生补升】超过有效期';
      this.form.getFieldDecorator('question', { initialValue: '【学生补升】超过有效期' });
      this.form.getFieldDecorator('reason', { initialValue: '该学生超过补升有效期' });
      this.form.getFieldDecorator('subject', { initialValue: this.currentViewData?.processVariables?.subjectName });
      this.form.getFieldDecorator('studentName', { initialValue: this.currentViewData?.processVariables?.studentName });
      this.form.getFieldDecorator('studentCode', { initialValue: this.currentViewData?.processVariables?.studentCode });
    },
    initSubmit(data) {
      const assigneesName = [];
      const assigneeIds = [];
      const taskAssigneeName = [];
      const assigneeLists = [];
      const taskAssigneeNameLists = [];
      const assigneeType = [];
      let taskAssigneesArray = [];
      const questionOptions = this.questionArray;
      let questionName = '';
      questionOptions.forEach((item) => {
        if (item.name.includes(this.processName)) {
          questionName = item.name;
          // questionName = item.name.substring(0, item.name.indexOf('##'));
        }
      });
      this.assignees.dealAccounts.forEach((item, index) => {
        item.accounts.forEach((k) => {
          assigneesName.push(k.fullName);
        });
        assigneeType.push(item.assigneeType);
        if (index === 0 && item.accounts) {
          item.accounts.forEach((k) => {
            taskAssigneeName.push({
              assigneeName: k.fullName,
              assignee: k.uuid,
            });
            assigneeIds.push(k.uuid);
          });
        }
        if (index === 1 && item.accounts) {
          item.accounts.forEach((k) => {
            taskAssigneeNameLists.push({
              assigneeName: k.fullName,
              assignee: k.uuid,
            });
            assigneeLists.push(k.uuid);
          });
        }
      });
      taskAssigneesArray = [
        {
          sid: 'promoteExpiredTask1',
          taskName: questionName,
          taskCategory: '【学生】补升审批',
          assignees: taskAssigneeName,
          type: assigneeType[0],
        },
      ];
      let changeCourseId = '';
      changeCourseId = this.$route.query.uuid;
      const datas = {
        businessKey: 'promote-expired-form',
        // 表单数据
        formData: {
          question: questionName,
          // courseInfo: data.courseInfo,
          subject: data.subject,
          studentName: data.studentName,
          studentCode: data.studentCode,
          dueDate: data.dueDate,
          description: data.description,
          reason: data.reason,
          imgs: data.imgs,
          assignees: assigneesName.join(','),
          carbonCopyUsers: '',
        },
        name: '【学生】补升审批',
        processKey: 'promote-expired',
        // 发起人ID
        startUserId: this.userInfo.userID,
        // 第一节点审批人
        transientVariables: {
          assigneeId: assigneeIds.join(','),
          dueDate: data.dueDate,
        },
        // 第二节点审批人
        variables: {
          startUserName: this.userInfo.fullName,
          startUserRole: this.userInfo.roleName,
          relatedProcessInstanceId: this.currentViewData.processInstanceId,
          relatedProcessName: this.currentViewData.category,
          firstCourseDate: this.currentViewData.processVariables.schedule.firstCourseDate,
          category: questionName,
        },
        taskAssignees: taskAssigneesArray,
      };
      createTakeOverForm(datas)
        .then((res) => {
          if (res.status === 200) {
            this.onCancel('submit');
            this.$message.success('发送成功');
          }
        })
        .catch(() => {
          this.onCancel(0);
        });
    },
    checkRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        delete getFieldsValue.description;
        this.isAssignees = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          const initTime = this.moment(formData.dueDate).format('YYYY-MM-DD HH:mm:ss');
          formData.dueDate = `${initTime.substring(0, 10)}T${initTime.substring(11)}`;
          this.images = [];
          this.fileList.forEach((item) => {
            this.images.push(item.url);
          });
          formData.imgs = this.images.join(',');
          this.initSubmit(formData);
        }
      });
    },
    onCancel(value) {
      this.$emit('approvaCancel', value);
    },
  },
};
</script>
<style lang="less">
.schedule-related {
  .ant-modal-content {
  }
  .ant-modal-header {
    border-radius: 30px 30px 0 0;
  }

  .feedBackbtn[disabled] {
    background-color: #f5f5f5;
  }
}
</style>
