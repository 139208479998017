<template>
  <div>
    <a-modal
      :title="titleTxt"
      :confirm-loading="confirmLoading"
      @ok="handleOkFirstDate"
      @cancel="handleCancelFirstDate"
      okText="确认"
      cancelText="暂不"
      :visible="visible"
    >
      <p>
        请确认学生<span style="color: rgb(0, 156, 255)">{{ studentInfo.studentName }}</span
        >的首课：
      </p>
      <!-- <div class="form-item">
        <span class="form-label">班级：</span>
        <a-select v-model="firstFromClassId" class="form-content" @change="handleClassChange" placeholder="请选择">
          <a-select-option :value="item.uuid" :key="index" v-for="(item, index) in classTypesArr">
            {{ item.className }}
          </a-select-option>
        </a-select>
      </div> -->
      <div class="form-item">
        <span class="form-label">首课课节：</span>
        <a-select
          v-model="firstCourseScheduleId"
          class="form-content"
          @change="handleCourseChange"
          placeholder="请选择"
        >
          <a-select-option :value="item.scheduleId" :key="index" v-for="(item, index) in courseTypesArr" :data="item">
            {{ item.scheduleName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="form-item">
        <span class="form-label"> 首课时间为：</span><span style="color: #e19202">{{ firstLesson }}（北京时间）</span>
      </div>
      <div class="form-item">
        <span class="form-label">首课注意事项：</span>
        <a-textarea class="form-content" v-model="remark" placeholder="请输入" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { querySchedule, queryScheduleNew } from '@/api/headTeacher';

export default {
  name: 'ConfirmLesson',
  props: {
    studentInfo: {
      type: Object,
      default: () => {},
    },
    showInfoData: {
      type: Object,
      default: () => {},
    },
    titleTxt: {
      type: String,
      default: '确认首课时间',
    },
  },
  data() {
    return {
      firstCourseScheduleName: '',
      firstFromClassName: '',
      firstCourseEndDate: null,
      firstCourseObj: {},
      courseName: '',
      visible: true,
      confirmLoading: false,
      courseTypesArr: [],
      classTypesArr: [],
      firstLesson: '',
      studentName: '',
      firstCourseScheduleId: '',
      firstFromClassId: '',
      remark: '',
    };
  },
  methods: {
    querySchedule(classId) {
      const params = {
        formClassId: classId,
        scheduleStatuses: ['COMPLETED', 'NORMAL'],
        stuScheduleStatuses: ['COMPLETED', 'NORMAL', 'ABSENT'],
        pageSize: 5,
        studentId: this.studentInfo.studentId,
      };
      querySchedule(params).then((res) => {
        this.courseTypesArr = res.data.content;
        this.lookCourseParams(this.firstCourseScheduleId);
      });
    },
    queryFirstLesson() {
      queryScheduleNew({ studentId: this.studentInfo.studentId, subject: this.studentInfo.courseSubject }).then(
        (res) => {
          this.courseTypesArr = res.data;
          this.lookCourseParams(this.firstCourseScheduleId);
        },
      );
    },
    handleClassChange(val) {
      this.firstLesson = '';
      this.firstCourseScheduleId = '';
      this.querySchedule(val);
    },
    // eslint-disable-next-line consistent-return
    handleOkFirstDate() {
      if (!this.firstFromClassId) {
        this.$message.warning('请选择班级');
        return false;
      }
      if (!this.firstCourseScheduleId) {
        this.$message.warning('请选择课节');
        return false;
      }
      const obj = {
        firstLesson: this.$moment(this.firstLesson).format('YYYY-MM-DD HH:mm'),
        firstCourseScheduleId: this.firstCourseScheduleId,
        firstFromClassId: this.firstFromClassId,
        remark: this.remark,
        firstCourseScheduleName: this.firstCourseScheduleName,
        firstFromClassName: this.firstFromClassName,
        firstCourseEndDate: this.$moment(this.firstCourseEndDate).format('YYYY-MM-DD HH:mm'),
      };
      this.$emit('handleOkFirstDate', obj);
    },
    handleCancelFirstDate() {
      this.$emit('handleCancelFirstDate');
    },
    handleCourseChange(val) {
      this.lookCourseParams(val);
    },
    lookCourseParams(scheduleId) {
      this.firstCourseScheduleId = scheduleId;
      const arr = this.courseTypesArr.filter((item) => {
        return item.scheduleId === scheduleId;
      });
      this.firstLesson = arr[0]?.startDateTime;
      this.firstCourseScheduleName = arr[0]?.scheduleName;
      this.firstFromClassName = arr[0]?.formClassName;
      this.firstCourseEndDate = arr[0]?.endDateTime;
      this.firstFromClassId = arr[0]?.formClassId;
    },
  },
  mounted() {
    // 回显下拉值
    this.firstFromClassId = this.showInfoData.firstFromClassId;
    this.firstLesson = this.showInfoData.currentFirstLessonDate;
    this.firstCourseScheduleId = this.showInfoData.firstCourseScheduleId;

    // const params = {
    //   studentId: this.studentInfo.studentId,
    //   courseType: this.studentInfo.courseType,
    //   courseSubject: this.studentInfo.courseSubject,
    // };
    // queryClass(params).then((res) => {
    //   this.classTypesArr = res.data;
    // });
    // this.querySchedule(this.showInfoData.firstFromClassId);
    this.queryFirstLesson();
  },
};
</script>

<style lang="less" scoped>
.form-item {
  margin-top: 10px;
  display: flex;
}
.form-label {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.form-content {
  flex: 1;
}
</style>
