<template>
  <ModalForm
    title="完成任务"
    :width="800"
    :onFinish="onFinish"
    formRef="uploadTaskFormRef"
    :labelCol="{ span: 6 }"
    :wrapperCol="{ span: 18 }"
  >
    <a-button type="link" slot="trigger">完成</a-button>
    <template #form="{ values }">
      <a-form-item label="沟通情况">
        <a-radio-group
          :options="communicationOptions"
          v-decorator="['offlineUploadCommStatus', { rules: [{ required: true, message: '请选择沟通情况' }] }]"
        />
      </a-form-item>
      <a-form-item label="沟通时间" v-if="values.offlineUploadCommStatus === '任务生成前，沟通过'">
        <a-date-picker
          style="width: 100%"
          :disabled-date="disbleAfterDateOption.disabledDate"
          :disabled-time="disbleAfterDateOption.disabledDateTime"
          v-decorator="['offlineUploadCommTime', { rules: [{ required: true, message: '请选择沟通时间' }] }]"
          format="YYYY-MM-DD HH:mm:ss"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          :defaultPickerValue="defaultPickerValue"
          show-time
        />
      </a-form-item>
      <a-form-item label="未沟通原因" v-if="values.offlineUploadCommStatus === '内容不合适，未沟通'">
        <a-radio-group
          :options="notCommReasonOptions"
          v-decorator="['offlineUploadNotCommReason', { rules: [{ required: true, message: '请选择未沟通原因' }] }]"
        />
      </a-form-item>
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ModalForm/index.vue';
import { getDisbleAfterDateOption } from '@/utils/util.js';
import { mapState } from 'vuex';
import storage from 'store';

export default {
  components: { ModalForm },
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    onFinishUploadTask: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      currentUserAvatar: (state) => state.userIM.currentUserAvatar,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
    }),
    form() {
      return this.$refs.uploadTaskFormRef;
    },
  },
  data() {
    return {
      userInfo: storage.get('userInfo'),
      disbleAfterDateOption: {},
      defaultPickerValue: null,
      communicationOptions: [
        { label: '任务生成后，已沟通', value: '任务生成后，已沟通' },
        { label: '任务生成前，沟通过', value: '任务生成前，沟通过' },
        { label: '内容不合适，未沟通', value: '内容不合适，未沟通' },
      ],
      notCommReasonOptions: [
        { label: '任务和学员情况不匹配', value: '任务和学员情况不匹配' },
        { label: '讲给家长/提示自己内容不适合', value: '讲给家长/提示自己内容不适合' },
        { label: '以上均不是', value: '以上均不是' },
      ],
    };
  },
  methods: {
    async onFinish(params = {}) {
      const { processVariables = {}, type, processInstanceId, id: taskId } = this.record;
      const { studentId, studentCode, subject, studentName } = processVariables;

      const formData = {
        studentId,
        studentCode,
        studentName,
        type,
        processInstanceId,
        taskId,
        subject,
        classAdminId: this.userInfo.userID,
        classAdminName: this.userInfo.fullName,
        headImageUrl: this.currentUserProfile.avatar ? this.currentUserProfile.avatar : this.currentUserAvatar,
        recordData: {
          followType: 'OFFLINE_UPLOAD',
          taskType: 'OFFLINE_UPLOAD',
          nextFollowRecord: false,
          nextStuFirstFollowRecord: false,
          ...params,
        },
      };

      await this.onFinishUploadTask(formData);
      return true;
    },
  },
  created() {
    const time = this.record.createTime
      ? this.$moment(this.record.createTime.replace(/[年月]/g, '-').replace('日', '') + ':00').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      : this.$moment().format('YYYY-MM-DD HH:mm:ss');
    this.defaultPickerValue = time;
    this.disbleAfterDateOption = getDisbleAfterDateOption(time);
  },
};
</script>
<style></style>
