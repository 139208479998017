<template>
   <a-modal
      title="提示"
      :visible=true
      @ok="handleGroup"
      @cancel="handleCancelGroup"
      okText="去沟通"
      cancelText="稍后"
    >
      <div class="group-wraptc"><span class="success-icon"></span>创建成功！本条任务已完成</div>
      <div class="group-txttc">
        你已成功创建与学生
        <span class="blueColor"> {{ createGroupObj.studentName }}</span
        >的服务群，系统将自动为您发送 一条与家长打招呼的消息，请及时到群里进行后续沟通：
      </div>
      <p class="group-txt">
        1.完成自我介绍<br />
        2.与家长约定「新生首通」的时间
      </p>
    </a-modal>
</template>

<script>
export default {
  name:'createGroup',
   props: {
    createGroupObj: {
      type: Object,
    }
    
  },
  data(){
    return{
      
    }
  },
  methods:{
    handleCancelGroup() {
     
      this.$emit("cancelGroup")
    },
    handleGroup() {
      this.$store.commit('setGroupName', this.createGroupObj.groupName);
      this.$store.commit('setGroupIdRoute', this.createGroupObj.groupID);
      this.$router.push({ path: '/headTeacher' });
     
    },
  }
}
</script>

<style lang="less" scoped>
.group-wraptc {
  display: flex;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}
.group-txttc {
  font-size: 16px;
  color: #333;
  padding: 20px 0;
}
.group-txt {
  font-size: 16px;
  color: #333;
}

.blueColor {
  color: #009cff;
}
.success-icon {
  width: 19px;
  height: 19px;
  background: url('~@/assets/service_icon_arrange_can.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  margin-right: 10px;
}
</style>