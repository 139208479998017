<template>
  <a-modal
    title="交接学生"
    :visible="visible"
    :footer="null"
    width="860px"
    dialogClass="resignation-related"
    @cancel="onCancel(0)"
  >
    <a-spin :spinning="modalLoading">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="交接类型">
          <a-select
            size="large"
            v-decorator="['takeOverType', { rules: [{ required: true, message: '请选择交接类型!' }] }]"
            placeholder="请选择交接类型"
            @change="changeTakeoverType"
          >
            <a-select-option v-for="(item, index) in takeOverArr" :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="交接班主任">
          <a-select
            size="large"
            show-search
            allowClear
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            @change="queryUnassignedStudent"
            @search="searchHeadmaster"
            placeholder="请选择交接班主任"
            v-decorator="['preClassAdminId', { rules: [{ required: true, message: '请选择交接班主任!' }] }]"
          >
            <a-select-option v-for="(item, index) in headmasterList" :key="item.uuid" :value="item.uuid">
              {{ `${item.fullName}(${item.email})` }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择交接时间段" :wrapper-col="{ span: 18 }">
          <a-range-picker
            size="large"
            style="width: 100%"
            v-decorator="['time', config]"
            @change="changeDate"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="分配学生">
          <a-table :pagination="false" bordered :data-source="dataSource" :columns="columns">
            <template slot="name" slot-scope="text, record">
              <EditClassAdmin
                @change="onCellChange(record.key, 'classAdmin', $event)"
                :classAdmin="record.classAdmin"
                :defaultHeadmasterNewList="record.defaultHeadmasterNewList || []"
              ></EditClassAdmin>
            </template>
            <template slot="subject" slot-scope="text, record">
              <sel-subject
                :subjectDisabled="record.disabled"
                :value="record.subject"
                @change="onCellChange(record.key, 'subject', $event)"
              ></sel-subject>
            </template>
            <template slot="selStudent" slot-scope="text, record">
              <addStudent
                @change="onCellChange(record.key, 'students', $event)"
                @selStudentFun="selStudentFun"
                :selChineseStudent="selChineseStudent"
                :selMathStudent="selMathStudent"
                :selEnglishStudent="selEnglishStudent"
                :preClassAdminId="preClassAdminId"
                :subject="selSubject"
                :defaultStudentList="record.defaultStudentList"
              ></addStudent>
            </template>
            <template slot="dueDateNum" slot-scope="text, record">
              <dueDateNum
                :value="record.dueDateNum"
                @change="onCellChange(record.key, 'dueDateNum', $event)"
              ></dueDateNum>
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm v-if="dataSource.length" title="确定删除该行吗?" @confirm="() => onDelete(record)">
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </template>
          </a-table>
          <div class="btn-list-wrap">
            <a-button class="editable-add-btn" @click="handleAdd"> 新增 </a-button>
            <a-upload
              @change="handleUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :file-list="fileList"
            >
              <a-button class="editable-add-btn"> 表格导入 </a-button>
            </a-upload>

            <span>{{ noSelTotalStudent }}人未分配</span>
          </div>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 12 }" style="margin-bottom: 0">
          <a-button style="width: 150px; height: 46px; border-radius: 10px; margin-right: 10px" @click="onCancel(0)">
            取消
          </a-button>
          <a-button
            style="width: 150px; height: 46px; border-radius: 10px; margin-right: 10px"
            type="primary"
            @click="handleSubmit"
            :disabled="noAssigness"
            class="feedBackbtn"
          >
            提交
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import { Modal } from 'ant-design-vue';

import {
  getHeadmasterList,
  queryUnassignedStudent,
  queryTakeOverStudent,
  submitTakeOverStudent,
  uploadDataForm,
} from '@/api/headTeacher';
// 首通处理时间
const dueDateNum = {
  template: `<div class="sel-subject">
                 <a-select :value="value" @change="handleChange"  default-value=3  placeholder="请选择">
                  <a-select-option v-for="(item, index) in dateList" :key="index" :value="item">
                      {{ item }}
                    </a-select-option>
              </a-select>
              </div>
    `,

  data() {
    return {
      dateList: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    };
  },
  props: {
    value: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
// 选择科目
const selSubject = {
  template: `<div class="sel-subject">
                 <a-select :value="value" default-value="CHINESE" :disabled="subjectDisabled" @change="handleChange">
                  <a-select-option v-for="(item, index) in subjectArr" :key="index" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
              </a-select>
              </div>
    `,
  props: {
    subjectDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      value: 'CHINESE',
    },
  },
  data() {
    return {
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
    };
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
const EditClassAdmin = {
  template: `<div class="sel-wrap">
                 <a-select
                      show-search
                      allowClear
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      @search="searchNewHeadmaster"
                      placeholder="请选择新班主任"
                      @change="handleChange"
                      :value="classAdmin.uuid"
                    >
                      <a-select-option v-for="item in headmasterNewList" :key="item.uuid" :value="item.uuid">{{
                          item.fullName
                        }}</a-select-option>
                    </a-select>
                    <div class="empty-txt" v-if="!this.classAdmin.uuid">不能为空</div>
              </div>
    `,
  props: {
    classAdmin: {
      type: Object,
      default: () => ({}),
    },
    defaultHeadmasterNewList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editable: false,
      headmasterNewList: [],
    };
  },
  mounted() {
    this.headmasterNewList = this.defaultHeadmasterNewList;
  },
  methods: {
    searchNewHeadmaster(name) {
      const params = {
        roles: ['CLASS_ADMIN'],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterNewList = res.data.content;
      });
    },
    handleChange(value) {
      const target = this.headmasterNewList.find((item) => item.uuid === value);
      this.$emit('change', target);
    },
  },
};
// 添加学生
const addStudent = {
  template: `
  <div>
   <a-button v-if="studentNum===0" @click="addStudent">添加学生</a-button>
   <span  v-else>已选择{{studentNum}}人 ></span>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      title="选择学生"
      ok-text="确定"
      cancel-text="取消"
       width="600px"
      @ok="confirmStudent"
      @cancel="cancelStudent"
    >
      <a-input-search
        placeholder="搜索学生姓名或学号"
        v-model="studentName"
        enter-button="搜索"
        size="large"
        @search="onSearch"
      />
      <a-table
        :rowKey="(record) => record.uuid"
        :maskClosable="false"
        :pagination="initPagination"
        @change="handleTableChange"
        size="small"
        :scroll="{ y: 400 }"
        :row-selection="{
          onSelect: onSelect,
          onSelectAll: onSelectAll,
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="studentColumns"
        :data-source="studentList"
      />
      <div style="margin-top: 10px">
        已选<span style="color: #009cff">{{ selectedRowKeys.length }}</span
        >人
      </div>
    </a-modal>
   
  </div>
         
    `,
  props: {
    subject: String,
    preClassAdminId: String,

    selChineseStudent: {
      type: Array,
      default: () => [],
    },
    selEnglishStudent: {
      type: Array,
      default: () => [],
    },
    selMathStudent: {
      type: Array,
      default: () => [],
    },
    defaultStudentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dateString: [],
      value: '',
      studentName: '',
      studentNum: 0,
      addStudentNum: 3,
      studentName: '',
      pageNum: 1,
      pageSize: 10,
      visible: false,
      studentList: [],
      selectedRowKeys: [],
      selectedRows: [],
      selStudent: [],
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      studentColumns: [
        {
          title: '学生姓名',
          dataIndex: 'fullName',
        },
        {
          title: '学号',
          dataIndex: 'code',
          width: 200,
        },
      ],
    };
  },

  methods: {
    onSelectChange(selectedRowKeys) {
      this.$nextTick(() => {
        this.selectedRowKeys = selectedRowKeys;
      });
    },
    onSelect(record, selected) {
      selected
        ? this.selectedRows.push(record)
        : this.selectedRows.splice(
            this.selectedRows.findIndex((x) => x.uuid === record.uuid),
            1,
          );
    },
    onSelectAll(selected, selectedRows, changeRows) {
      this.selectedRows = selected
        ? this.selectedRows.concat(changeRows)
        : this.selectedRows.filter((x) => !changeRows.find((i) => i.uuid === x.uuid));
    },
    onSearch() {
      this.pageNum = 1;
      this.queryTakeOverStudent();
    },
    cancelStudent() {
      this.studentList = [];
    },
    // 选择学生
    confirmStudent() {
      this.visible = false;
      this.studentNum = this.selectedRowKeys.length;
      this.$emit('selStudentFun', this.subject, this.selectedRowKeys, this.selectedRows);
      const students = [];
      this.selectedRows.forEach((item) => {
        const obj = {
          uuid: item.uuid,
          fullName: item.fullName,
          code: item.code,
          timezone: item.timezone,
        };
        students.push(obj);
      });

      this.$emit('change', students);
    },
    handleTableChange(pagination, filters, sorter) {
      this.pageNum = pagination.current;
      this.initPagination.current = pagination.current;
      this.queryTakeOverStudent();
    },

    queryTakeOverStudent() {
      const params = {
        ownerId: this.preClassAdminId,
        subject: this.subject,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        name: this.studentName,
      };
      if (this.subject === 'CHINESE') {
        params.notStudentIds = this.selChineseStudent;
      } else if (this.subject === 'MATH') {
        params.notStudentIds = this.selMathStudent;
      } else if (this.subject === 'ENGLISH') {
        params.notStudentIds = this.selEnglishStudent;
      }
      queryTakeOverStudent(params).then((res) => {
        this.studentList = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
      });
    },
    cancelStudent() {
      this.visible = false;
    },
    addStudent() {
      // 必选选择离职班主任
      if (this.preClassAdminId) {
        this.visible = true;
        this.onSearch();
      } else {
        this.$message.warning('请先选择离职班主任');
      }
    },
    handleChange(value) {
      console.log(value);
      this.value = value;
      this.$emit('change', value);
    },
  },
  created() {
    if (this.defaultStudentList.length) {
      this.selectedRows = this.defaultStudentList;
      this.selectedRowKeys = this.defaultStudentList.map((item) => item.uuid);
      this.confirmStudent();
    }
  },
  mounted() {},
};
export default {
  props: {
    // scheduleName: {
    //   type: String,
    //   default: '',
    // },
    currentViewData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    EditClassAdmin,
    addStudent,
    selSubject,
    dueDateNum,
  },
  data() {
    return {
      modalLoading: false,
      isTableCheck: false, // 校验分配学生的表单
      unassignedStudentNum: 0,
      noSelTotalStudent: 0,
      classAdminStudent: [], // 分配学生表格里面的数据
      selChineseStudent: [], // 选择中文科目对应的学生
      selEnglishStudent: [], // 选择中文科目对应的学生
      selMathStudent: [], // 选择数学科目对应的学生
      selSubject: 'CHINESE',
      studentNum: 0,
      preClassAdminId: undefined,
      headmasterList: [],
      dataSource: [],
      columns: [
        {
          title: '新班主任',
          dataIndex: 'classAdmin',
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '选择科目',
          dataIndex: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '分配学生名单',
          dataIndex: 'student',
          scopedSlots: { customRender: 'selStudent' },
        },
        {
          title: '首通处理时间',
          dataIndex: 'dueDateNum',
          scopedSlots: { customRender: 'dueDateNum' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      classAdminArr: [],
      takeOverArr: [
        { label: '离职交接', value: 'RESIGN' },
        { label: '普通交接', value: 'NORMAL' },
      ],
      // uuid: storage.get(UUID),
      userInfo: storage.get('userInfo'),
      // teacherName: storage.get(TEACHERINFO).teacherName,
      visible: true,
      form: this.$form.createForm(this),
      formData: null,
      questionArray: [{ name: '【学生补升】超过有效期' }],
      config: {
        rules: [{ required: true, message: '请选择日期(北京时间)!' }],
      },
      fileList: [],
      assignees: null,
      images: [],
      imgInfo: null,
      isAssignees: false,
      courseInfoArray: [],
      studentsAray: [],
      processName: '',
      options: null,
      noAssigness: false,
      classArray: [],
      pageNum: 1,
      changeCourseId: '',
      courseId: '',
      newStudents: [],
    };
  },
  computed: {
    preClassAdmin() {
      const target = this.headmasterList.find((item) => item.uuid === this.preClassAdminId);
      console.log('preClassAdminpreClassAdminpreClassAdminpreClassAdminpreClassAdmin');
      console.log(target);
      return target;
    },
  },

  methods: {
    moment,
    async handleUpload(e) {
      const { file } = e;
      const params = new FormData();
      params.append('file', file.originFileObj);
      params.append('type', 'TRANSFER_OF_STUDENT');
      await new Promise((resolve, reject) => {
        Modal.confirm({
          content: '表格导入后将覆盖原有数据，是否继续导入？',
          title: '提示',
          maskClosable: false,
          onOk() {
            resolve();
          },
          onCancel() {
            reject();
          },
        });
      });
      this.modalLoading = true;
      try {
        const response = await uploadDataForm(params);

        // 检查响应头中的Content-Type
        const contentType = response.headers['content-type'];
        // 文档格式正确，处理数据
        if (contentType.includes('application/json')) {
          // 如果是JSON，将blob转换为JSON
          const reader = new FileReader();
          reader.readAsText(response.data, 'UTF-8');

          // 成功的处理
          await new Promise((resolve) => {
            reader.onload = async () => resolve();
          });
          const jsonResponse = JSON.parse(reader.result);
          const preClassAdmin = jsonResponse.resultList?.[0]?.preClassAdmin;
          const preClassAdminId = preClassAdmin.uuid;
          this.form.setFieldsValue({ preClassAdminId });
          this.headmasterList = [preClassAdmin];
          this.selChineseStudent = [];
          this.selMathStudent = [];
          this.selEnglishStudent = [];

          await this.queryUnassignedStudent(preClassAdminId);

          this.dataSource = [];
          await new Promise((resolve) => this.$nextTick(() => resolve()));
          this.dataSource = jsonResponse.resultList.map((item, index) => {
            return {
              ...item,
              key: index,
              classAdmin: item.newClassAdmin,
              defaultHeadmasterNewList: [item.newClassAdmin],
              defaultStudentList: item.students,
              disabled: true,
            };
          });
        } else {
          await new Promise((resolve, reject) => {
            Modal.confirm({
              content: '文件内容不符合要求，请下载查看详情',
              title: '提示',
              maskClosable: false,
              okText: '下载',
              cancelText: '取消',
              onOk: () => resolve(),
              onCancel: () => reject(),
            });
          });
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'filename.xlsx'); // 设定下载文件名
          document.body.appendChild(link);
          link.click();
        }
      } finally {
        this.modalLoading = false;
      }
    },
    submitTakeOverStudent(data) {
      const params = {
        takeOverType: data.takeOverType,
        preClassAdmin: data.preClassAdmin,
        takeOverStartTime: data.takeOverStartTime,
        takeOverEndTime: data.takeOverEndTime,
        classAdminStudent: data.classAdminStudent,
      };
      submitTakeOverStudent(params).then((res) => {
        this.$message.success('创建成功');
        this.onCancel('submit');
      });
    },
    changeTakeoverType(val) {},
    selStudentFun(subject, studentIds, students) {
      if (subject === 'CHINESE') {
        this.selChineseStudent = this.selChineseStudent.concat(studentIds); // 选择中文科目对应的学生
      } else if (subject === 'MATH') {
        this.selMathStudent = this.selMathStudent.concat(studentIds); // 选择数学科目对应的学生
      } else if (subject === 'ENGLISH') {
        this.selEnglishStudent = this.selEnglishStudent.concat(studentIds); // 选择数学科目对应的学生
      }

      this.studentNum = studentIds.length;
      this.noSelTotalStudent =
        this.unassignedStudentNum -
        (this.selChineseStudent.length + this.selMathStudent.length + this.selEnglishStudent.length);
    },
    // 获取待分配的总人数
    async queryUnassignedStudent(uuid) {
      this.preClassAdminId = uuid;
      const params = {
        classAdminId: uuid,
      };
      await queryUnassignedStudent(params).then((res) => {
        this.noSelTotalStudent = res.data.content;
        this.unassignedStudentNum = res.data.content;
      });
    },
    onCellChange(key, dataIndex, value) {
      console.log(key);
      if (dataIndex === 'subject') {
        this.selSubject = value;
      } else {
        this.selSubject = 'CHINESE';
      }
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        if (dataIndex === 'students') {
          target.disabled = true;
        }
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
      console.log(this.dataSource);
    },
    handleAdd() {
      // 新增之前，判断表格里面分配学生的表单信息是否完整
      const { dataSource } = this;
      if (dataSource.length > 0) {
        const obj = dataSource[dataSource.length - 1];
        for (const key in obj) {
          if (obj[key] == null || obj.students.length === 0) {
            this.$message.warning('分配学生的表单信息不完整');

            return false;
          }
        }
      }
      const newData = {
        key: dataSource.length,
        classAdmin: {},
        subject: 'CHINESE',
        dueDateNum: 3,
        students: [],
        disabled: false,
      };

      this.dataSource = [...dataSource, newData];
    },
    searchHeadmaster(name) {
      const params = {
        roles: ['CLASS_ADMIN'],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterList = res.data.content;
        console.log(this.headmasterList, 22222);
      });
    },
    onDelete(record) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter((item) => item.key !== record.key);

      // 也要删除对应选中的学生

      const mathStudent = [...this.dataSource].filter((item) => {
        return item.subject === 'MATH';
      });

      this.selMathStudent = [];
      mathStudent.forEach((item) => {
        item.students.forEach((subItem) => {
          if (subItem.uuid) {
            this.selMathStudent = this.selMathStudent.concat(subItem.uuid);
          }
        });
      });

      const chineseStudent = [...this.dataSource].filter((item) => {
        return item.subject === 'CHINESE';
      });

      this.selChineseStudent = [];
      chineseStudent.forEach((item) => {
        item.students.forEach((subItem) => {
          if (subItem.uuid) {
            this.selChineseStudent = this.selChineseStudent.concat(subItem.uuid);
          }
        });
      });

      const englishStudent = [...this.dataSource].filter((item) => {
        return item.subject === 'ENGLISH';
      });

      this.selEnglishStudent = [];
      englishStudent.forEach((item) => {
        item.students.forEach((subItem) => {
          if (subItem.uuid) {
            this.selEnglishStudent = this.selEnglishStudent.concat(subItem.uuid);
          }
        });
      });

      // 也要删除对应的学生
      this.noSelTotalStudent =
        this.unassignedStudentNum -
        (this.selChineseStudent.length + this.selMathStudent.length + this.selEnglishStudent.length);
    },

    changeDate(date, dateString) {
      this.dateString = dateString;
      console.log(dateString);
    },
    checkRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        delete getFieldsValue.description;
        this.isAssignees = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
      });
    },
    handleSubmit(e) {
      console.log(this.dataSource);
      e.preventDefault();
      this.form.validateFields((err, values) => {
        const classAdminStudent = [];
        if (!err) {
          if (this.dataSource.length === 0) {
            this.$message.warning('请分配学生');
            return false;
          }

          this.dataSource.forEach((obj) => {
            for (const key in obj) {
              if (obj[key] == null || obj.students.length === 0) {
                this.$message.warning('分配学生的表单信息不完整');
                throw new Error();
              }
            }

            classAdminStudent.push({
              dueDateNum: obj.dueDateNum,
              subject: obj.subject,
              classAdmin: obj.classAdmin,
              students: obj.students,
            });
          });

          const formData = {
            takeOverType: values.takeOverType,
            preClassAdmin: this.preClassAdmin,
            takeOverStartTime: this.dateString[0],
            takeOverEndTime: this.dateString[1],
            classAdminStudent,
          };
          // 如果是离职交接。所有学生必须分配完
          if (values.takeOverType === 'RESIGN') {
            if (this.noSelTotalStudent > 0) {
              this.$message.warning('必须分配完所有学生');
              return false;
            }
          }
          this.submitTakeOverStudent(formData);
        }
      });
    },
    onCancel(value) {
      this.$emit('cancel', value);
    },
  },
};
</script>
<style lang="less">
.resignation-related {
  .ant-modal-content {
    border-radius: 30px;
  }
  .ant-modal-header {
    border-radius: 30px 30px 0 0;
  }

  .feedBackbtn[disabled] {
    background-color: #f5f5f5;
  }
}
.empty-txt {
  color: red;
}

.btn-list-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
