<template>
  <a-modal
      title="确认完成"
      :visible=true
      @ok="completeMakeUp"
      @cancel="cancelMakeUp"
      okText="确认"
      cancelText="取消"
    >
      <p>
        请选择学生<span class="blueColor"> {{ promotionObj.studentName }}（{{ promotionObj.studentCode }}）</span
        >的补升情况，确认后该任务将标记为已完成
      </p>
      <a-radio-group v-model="promoteFlag" @change="onChangeMakeUp">
        <div class="sel-makeWrap">
          <div class="sel-inputWrap">
            <a-radio value="PURCHASED"></a-radio>
          </div>
          <div class="sel-inputWrap">
            <p class="sel-makeTxt">已购买补升</p>
            <p>家长已完成支付，且财务已确认到账的</p>
          </div>
        </div>
        <div class="sel-makeWrap">
          <div class="sel-inputWrap">
            <a-radio value="GIVE_UP"></a-radio>
          </div>
          <div class="sel-inputWrap">
            <p class="sel-makeTxt">已核实该学员放弃补升</p>
            <p>多次与家长沟通后，家长依然坚持放弃补升权益的</p>
          </div>
        </div>
      </a-radio-group>
    </a-modal>
</template>

<script>
import {completeMakeUp}from '@/api/headTeacher';
export default {
  props:{
    promotionObj:{
      type:Object
    }
  },
  data(){
    return{
       promoteFlag: 'PURCHASED',
    }
  },
  methods:{
     // 完成补升
    completeMakeUp() {
      const params = {
        taskId: this.promotionObj.taskId,
        type: this.promotionObj.type,
        classAdminId: this.promotionObj.classAdminId,
        promote: this.promoteFlag,
        studentId: this.promotionObj.studentId,
        subject: this.promotionObj.subject,
      };
      completeMakeUp(params).then((res) => {
        this.cancelMakeUp();
        this.$message.success('已完成！');
        this.$emit('completeMakeUp')
       
      });
    },
    cancelMakeUp() {
      
      this.$emit("cancelMakeUp")
    },
     onChangeMakeUp(e) {
      this.promoteFlag = e.target.value;
    },
  }
}
</script>

<style lang="less" scoped>
.sel-makeWrap {
  display: flex;
  padding: 10px;
  border: 1px solid #ebedf0;
  border-radius: 10px;
  margin-top: 15px;
}
.sel-makeTxt {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.sel-wrap{
  height:58vh;
  overflow-y: auto;
}
.sel-wrap1{
  height:80vh;
  overflow-y: auto;
}
.blueColor{
  color:#43D186;
}
</style>