<template>
  <a-modal
    title="发起审批"
    :visible="visible"
    @ok="handleSubmit"
    :okButtonProps="{
      props: {
        disabled: noAssigness,
        loading: iconLoading,
      },
    }"
    okText="提交"
    width="680px"
    dialogClass="schedule-related"
    @cancel="onCancel(0)"
  >
    <div class="modal-height">
      <a-form :form="form" v-if="formData" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item :label="formData.fields[0].name">
          <a-select
            v-decorator="[formData.fields[0].id]"
            placeholder="请选择审批类型"
            disabled
            @change="changeQuestion"
          >
            <a-select-option
              v-for="(item, index) in taskOverTypeArray"
              :key="index"
              :value="item.name.substring(item.name.indexOf('##') + 2)"
            >
              {{ item.name.substring(0, item.name.indexOf('##')) }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[1].name">
          <a-select
            labelInValue
            show-search
            allowClear
            :filter-option="filterOption"
            option-filter-prop="children"
            :disabled="disabled"
            @search="searchStudentName"
            v-decorator="[formData.fields[1].id, { rules: [{ required: true, message: '请选择学生!' }] }]"
            placeholder="请选择学生"
          >
            <a-select-option v-for="item in studentList" :disabled="disabled" :key="item.uuid" :value="item.code"
              >{{ item.fullName }}（{{ item.code }}）</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[2].name" :wrapper-col="{ span: 18 }">
          <a-select
            v-decorator="[formData.fields[2].id, { rules: [{ required: true, message: '请选择科目!' }] }]"
            placeholder="请选择科目"
          >
            <a-select-option value="CHINESE">中文</a-select-option>
            <a-select-option value="MATH">数学</a-select-option>
            <a-select-option value="ENGLISH">英文</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="formData.fields[3].name">
          <a-date-picker
            style="width: 100%"
            format="YYYY-MM-DD HH:mm"
            disabled
            :disabled-date="disabledDate"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择最迟解决时间（北京时间）"
            v-decorator="[formData.fields[3].id]"
          />
        </a-form-item>
        <a-form-item :label="formData.fields[4].name" v-show="hideClass">
          <a-input v-decorator="[formData.fields[4].id]" disabled />
        </a-form-item>
        <a-form-item :label="formData.fields[5].name">
          <a-upload
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="[formData.fields[5].id, { rules: [{ required: true, message: '请上传图片!' }] }]"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <!-- <a-form-item label="备 注">
          <a-textarea placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" v-decorator="['remark']" />
        </a-form-item> -->
        <Handler v-if="assignees && isAssignees" :assignees="assignees" />
        <!-- <a-form-item :wrapper-col="{ span: 12, offset: 12 }" style="margin-bottom: 0">
          <a-button @click="onCancel(0)"> 取消 </a-button>
          <a-button type="primary" @click="handleSubmit" :loading="iconLoading" :disabled="noAssigness" class="feedBackbtn"> 提交 </a-button>
        </a-form-item> -->
      </a-form>
    </div>
    <a-modal
      title="操作提示"
      :visible="reminderTC"
      @ok="saveExamine"
      @cancel="cancelExamine"
      okText="确定提交"
      cancelText="取 消"
      :okButtonProps="{
        props: {
          loading: reminderLoading,
        },
      }"
    >
      <div>
        注意：该学员{{ `${studentInfo}${getDdlLabel(subjectArr, submitParams.subject)}` }}入学时间为<span
          style="color: red"
          >{{ entranceDate }}</span
        >
      </div>
    </a-modal>
  </a-modal>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import Handler from '@/components/WKHandler/index.vue';
import { imgpostUrl } from '@/api/register';
import { getDdlLabel } from '@/utils/util';

import { UUID, USERINFOSERVER, ROLES } from '@/store/mutation-types';
import {
  initFormResign,
  initOptions,
  initAssignees,
  createTakeOverForm,
  getStudentInfo,
  getUploadOssToken,
  studentShareForm,
  enrollmentTime,
} from '@/api/headTeacher';
export default {
  components: { Handler },
  props: {
    reSubmitData: {
      type: Object,
      default: () => {},
    },
    classInfoData: {
      type: Object,
      default: () => {},
    },
    studentProfile: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],

      studentInfo: '',
      reminderLoading: false,
      entranceDate: '',
      submitParams: {},
      reminderTC: false,
      hideClass: false,
      iconLoading: false,
      fileList: [],
      studentList: [],
      uuid: storage.get(UUID),
      accountId: undefined,
      teacherName: storage.get(USERINFOSERVER).fullName,
      roles: storage.get(ROLES),
      visible: true,
      form: this.$form.createForm(this),
      formData: null,
      assignees: null,
      isAssignees: false,
      processName: '',
      options: null,
      noAssigness: false,
      classArray: [],
      pageNum: 1,
      changeCourseId: '',
      studentArray: [],
      lessonsInvolvedData: [],
      expandClassNum: '',
      questionVale: 'student-shared',
      upGradeSection: [],
      taskOverTypeArray: [{ name: '【学生分享赠课链接确认】##student-shared' }],
      courseStructure: [],
      renderedOptions: [],
      dataList: null,
      num: 1,
      resignVisible: null,
    };
  },
  mounted() {
    this.initForm(`${this.questionVale}-form`); // adjust-time-take-over-form  upgrade-take-over-form
  },
  methods: {
    moment,
    cancelExamine() {
      this.reminderTC = false;
    },
    saveExamine() {
      this.reminderLoading = true;
      this.studentShareForm(this.submitParams);
    },
    searchStudentName(name) {
      this.getStudentInfo(name);
    },
    getStudentInfo(name) {
      const params = {
        name,
      };
      getStudentInfo(params)
        .then((res) => {
          this.reminderLoading = false;
          if (res.status === 200) {
            this.studentList = res.data?.content?.list;
          }
        })
        .catch(() => {
          this.reminderLoading = false;
        });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },

    onCanceltips() {
      this.resignVisible = null;
    },
    showTips() {
      this.resignVisible = true;
    },

    initQuestion() {},
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.url,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
          console.log(this.fileList);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    changeQuestion(value) {
      console.log(value);
      this.questionVale = value;
      this.initForm(`${value}-form`);
      this.assignees = null;
      this.isAssignees = false;
    },
    disabledDate(current) {
      return current && current < moment().subtract(1, 'days');
    },
    initAssigness(value) {
      this.processName = value;
      const data = { accountIds: [], businessKey: 'student-shared-form', processKey: 'student-shared' };
      const accountArray = this.studentArray;
      if (this.questionVale !== 'resign-take-over') {
        accountArray.forEach((item) => {
          if (item.classAdmin && item.classAdmin.CHINESE) {
            data.accountIds.push(item.classAdmin.CHINESE.accountId);
          }
        });
      }
      this.assignees = null;

      initAssignees(data)
        .then((res) => {
          this.assignees = res.data.content;
          console.log();
          this.noAssigness = false;
          this.isAssignees = true;
        })
        .catch(() => {
          this.noAssigness = true;
        });
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    initForm(value) {
      this.formData = null;
      const params = {
        type: value,
      };
      initFormResign(params).then((res) => {
        this.formData = res.data.content;
        // 获取处理人
        this.initAssigness();
        // this.form.getFieldDecorator('remark', { initialValue: '默认赠送1课时' });
        this.form.getFieldDecorator('question', { initialValue: 'student-shared' });
        this.form.getFieldDecorator('dueDate', { initialValue: moment().add(2, 'days').format('YYYY-MM-DD HH:mm') });
        if (this.disabled) {
          console.log(this.studentProfile.fullName);
          this.form.getFieldDecorator('studentCode', {
            initialValue: {
              label: `${this.studentProfile.fullName}（${this.studentProfile.code}）`,
              key: this.studentProfile.code,
            },
          });
        }
      });
      // 拿去枚举的类型
      initOptions().then((res) => {
        this.options = res.data.content;
      });
    },
    initSubmit(data) {
      const assigneesName = [];
      const taskAssigneesArray = [];
      const studentsArray = [];
      const questionOptions = this.taskOverTypeArray;
      let questionName = '';
      questionOptions.forEach((item) => {
        if (item.name.includes(this.questionVale)) {
          questionName = item.name.substring(0, item.name.indexOf('##'));
        }
      });
      // this.classInfoData.students.forEach((k) => {
      //   studentsArray.push({ name: k.name, id: k.id, code: k.code });
      // });
      // // 升级交接班节点Sid
      // const upgradeSid = [
      //   'sid-35A0A91F-6A52-4BDA-B958-450CAFDDEE69',
      //   'sid-321221BE-14B7-4D92-838B-9256759AC9C1',
      //   'sid-9DA2C130-2CB9-40B8-9D43-D092CFE9BBB0',
      // ];
      // // 调整时间Sid
      // const adjustSid = [
      //   'sid-013FBEAF-0BE4-429A-A986-E19F011B1AC1',
      //   'sid-56BD6F95-7700-402D-93D4-BE4AECEFA5B7',
      //   'sid-82589C1E-7330-4E1C-AEED-F1829E832C45',
      // ];
      // 提交人角色
      const roleStr = [];
      this.roles.forEach((i) => {
        roleStr.push(this.initOptionsType(i, this.options.userRole));
      });
      // 离职时间Sid
      const resignSid = ['userTask1', 'userTask2'];
      let sidArray = [];
      let formData = {};
      let dueDateTime = '';
      // if (data.question === 'upgrade-take-over') {
      //   sidArray = upgradeSid;
      //   dueDateTime = `${data.takeOverDate.substring(0, 10)}T${data.takeOverDate.substring(11)}`;
      //   formData = {
      //     question: questionName,
      //     className: data.className,
      //     classType: data.classType,
      //     takeOverDate: data.takeOverDate,
      //     firstCourseDate: data.firstCourseDate,
      //     reasonable: data.reasonable.substring(0, 1),
      //     takeOverPeriod: data.takeOverPeriod,
      //     studyProgress: data.studyProgress,
      //     upgradeProgress: data.upgradeProgress,
      //     upgradeFirstCourseDate: data.takeOverDate,
      //     description: data.description,
      //     classState: data.classState,
      //     outProcedure: data.outProcedure,
      //     reason: data.reason,
      //     assignees: assigneesName.join(','),
      //     carbonCopyUsers: '',
      //     students: studentsArray,
      //   };
      //   this.initClassInfo([this.classInfoData]);
      // } else if (data.question === 'adjust-time-take-over') {
      //   sidArray = adjustSid;
      //   dueDateTime = `${data.nextCourseDate.substring(0, 10)}T${data.nextCourseDate.substring(11)}`;
      //   formData = {
      //     question: questionName,
      //     selectDate: data.selectDate,
      //     outProcedure: data.outProcedure,
      //     reason: data.reason,
      //     firstCourseDate: data.firstCourseDate,
      //     className: data.className,
      //     students: studentsArray,
      //     studyProgress: data.studyProgress,
      //     nextCourseDate: data.nextCourseDate,
      //     description: data.description,
      //     classState: data.classState,
      //     assignees: assigneesName.join(','),
      //     carbonCopyUsers: '',
      //   };
      // } else {
      sidArray = resignSid;
      const currentTime = moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss');
      dueDateTime = `${currentTime.substring(0, 10)}T${currentTime.substring(11)}`;
      formData = {
        applyUser: data.applyUser,
        question: questionName,
        takeOverDate: data.takeOverDate,
        takeOverPeriod: data.takeOverPeriod,
        outProcedure: data.outProcedure,
        description: data.description,
        assignees: assigneesName.join(','),
        carbonCopyUsers: '',
      };
      // this.initClassInfo([this.classInfoData]);
      // }
      // 处理人节点
      if (this.assignees) {
        this.assignees.dealAccounts.forEach((item, index) => {
          item.accounts.forEach((k) => {
            assigneesName.push(k.fullName);
          });
          const assigneeIds = [];
          const taskAssigneeName = [];
          item.accounts.forEach((k) => {
            taskAssigneeName.push({
              assigneeName: k.fullName,
              assignee: k.uuid,
            });
            assigneeIds.push(k.uuid);
          });
          taskAssigneesArray.push({
            sid: sidArray[index],
            taskName: questionName,
            taskCategory: '班主任离职申请',
            assignees: taskAssigneeName,
            type: item.assigneeType,
          });
        });
        formData.assignees = assigneesName.join(',');
      }
      let changeCourseId = '';
      if (this.reSubmitData) {
        changeCourseId = this.changeCourseId || this.reSubmitData?.processVariables.courseScheduleId;
      } else {
        changeCourseId = this.$route.query.uuid;
      }
      // 表单启动参数
      const datas = {
        businessKey: `${data.question}-form`,
        // 表单数据
        formData,
        name: '班主任离职申请',
        processKey: data.question,
        // 发起人ID
        startUserId: this.accountId,
        // 第二节点审批人
        variables: {
          startUserName: this.teacherName,
          startUserRole: 'CLASS_ADMIN',
          courseScheduleId: changeCourseId,
          dueDate: dueDateTime,
          processType: data.question,
          students: studentsArray,
          relatedClass: this.lessonsInvolvedData,
          applyUserRoles: roleStr.join(','),
          applyUserUuid: this.uuid,
        },
        taskAssignees: taskAssigneesArray,
      };
      createTakeOverForm(datas)
        .then((res) => {
          if (res.status === 200) {
            this.onCancel('submit');
          }
        })
        .catch(() => {
          this.onCancel(0);
        });
    },
    checkClassRequired() {
      this.$nextTick(() => {});
    },
    checkRequiredOne() {
      // this.form.getFieldDecorator('outProcedure', { initialValue: undefined });
      // console.log(this.form.getFieldDecorator('outProcedure', {}));
      this.checkRequired();
    },
    checkRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        this.isAssignees = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
      });
    },
    studentShareForm(params) {
      studentShareForm(params)
        .then((res) => {
          this.iconLoading = false;
          this.reminderTC = false;
          this.$message.success('任务创建成功');
          this.onCancel('submit');
        })
        .catch(() => {
          this.reminderTC = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.iconLoading = true;

          const formData = values;

          const params = {
            subject: formData.subject,
            url: this.fileList[0].url,
            // remark: formData.remark,
          };
          // if(this.disabled){//从学生来的
          //   params.studentCode=this.studentProfile.code
          //   this.studentInfo=`${this.studentProfile.fullName}（${this.studentProfile.code}）`
          // }else{
          //  params.studentCode=formData.studentCode.key
          //    this.studentInfo=formData.studentCode
          // }
          params.studentCode = formData.studentCode.key;
          this.studentInfo = formData.studentCode.label;
          this.submitParams = params;
          const params1 = {
            studentCode: params.studentCode,
            subject: params.subject,
          };
          enrollmentTime(params1).then((res) => {
            const data = res.data.content;

            this.reminderLoading = false;
            if (data.reminder) {
              // 超过入学时间
              this.reminderTC = true;
              this.entranceDate = data.entranceDate;
              this.iconLoading = false;
            } else {
              this.studentShareForm(params);
            }
          });
        }
      });
    },
    onCancel(value) {
      this.$emit('cancal', value);
    },
  },
};
</script>
<style lang="less">
.schedule-related {
  .associatedClass {
    padding: 10px 0 0 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-top: 10px;
    .associatedClassInfo {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .class-name {
        display: flex;
        justify-content: flex-start;
        width: 92%;
      }
      p {
        max-width: 80%;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 0;
      }
      .title,
      .classInfoExpand {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
      .studnets {
        display: flex;
        flex-wrap: wrap;
        span {
          margin-right: 5px;
        }
      }
    }
    .involved-lesson {
      flex-wrap: wrap;
      width: 100%;
      p {
        max-width: 100%;
        font-size: 14px;
        margin: 6px 0 0;
      }
    }
  }
  .ant-modal-header {
    border-radius: 30px 30px 0 0;
  }

  .feedBackbtn[disabled] {
    background-color: #f5f5f5;
  }
}
.submit-tips {
  .ant-modal-content {
    padding: 20px 20px 0;

    .btn {
      display: flex;
      justify-content: space-between;
      .ant-btn {
        width: 200px;
        height: 46px;
        margin: 20px 0 40px;
        border-radius: 10px;
        font-size: 16px;
      }
    }
  }
  .tips-main {
    text-align: center;
    .tips-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 20px;
    }
    .tips-text {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;
      span {
        font-weight: 600;
      }
    }
    .tips-err {
      font-size: 18px;
      font-weight: 400;
      color: #ff5353;
      line-height: 25px;
      text-align: left;
    }
  }
}
.modal-height {
  height: 508px;
  overflow-y: auto;
}
</style>
