<template>
  <a-table
    @change="handleTableChange"
    :loading="loading"
    :columns="columns"
    :pagination="pagination"
    :row-selection="showRow ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange } : undefined"
    :data-source="tableData"
    :expandRowByClick="true"
    @expand="handleExpand"
    :expandedRowKeys="expandedRowKeys"
    :rowKey="
      (record) => {
        return record.id;
      }
    "
  >
    <div slot="expandIcon" slot-scope="props" v-if="props.record.type === 'OFFLINE_UPLOAD'">
      <a-icon type="down" v-if="!props.expanded" :style="{ color: '#04CB94' }" />
      <a-icon type="up" v-else :style="{ color: '#04CB94' }" />
    </div>
    <div
      slot="expandedRowRender"
      slot-scope="record"
      v-if="record.type === 'OFFLINE_UPLOAD'"
      class="offline-upload-wrapper"
    >
      <div class="upload-info">
        <!-- 总共七条-->
        <template v-for="(item, index) in Array.from({ length: 7 })">
          <div
            v-if="
              getUploadFields(record, `trait${index + 1}`) ||
              getUploadFields(record, `talkToParent${index + 1}`) ||
              getUploadFields(record, `talkToSelf${index + 1}`)
            "
            class="upload-item"
            :key="index"
          >
            <div class="info-row">
              <div class="label">【学员特征】</div>
              <div class="value" v-html="getUploadFields(record, `trait${index + 1}`, '-')"></div>
            </div>
            <div class="info-row">
              <div class="label">【讲给家长】</div>
              <div class="value" v-html="getUploadFields(record, `talkToParent${index + 1}`, '-')"></div>
            </div>
            <div class="info-row">
              <div class="label">【提示自己】</div>
              <div class="value" v-html="getUploadFields(record, `talkToSelf${index + 1}`, '-')"></div>
            </div>
          </div>
        </template>
      </div>
      <div class="upload-operation" v-if="activeName === 'nofinish' && record.assignee === userInfo.userID">
        <OfflineComplete :record="record" :onFinishUploadTask="onFinishUploadTask" />
        <!-- <a-button
          type="link"
          :loading="offlinefinishLoading === record.id"
          @click="() => handleFinishOfflineTask(record)"
        >
          完成
        </a-button> -->
      </div>
    </div>
    <span
      :class="record.processVariables.expireFlag && activeName === 'nofinish' ? 'redColor' : ''"
      slot="dueDate"
      slot-scope="time, record"
      >{{ time }}</span
    >
    <div slot="country" slot-scope="text, record">
      {{
        getLabelFromOptions(countryOptions, record.country)
          ? getLabelFromOptions(countryOptions, record.country)
          : record.country
      }}
    </div>
    <div slot="availableTalk" slot-scope="text, record">
      <p v-if="!record.lastAvailableTalkSeconds"></p>
      <p v-else-if="record.lastAvailableTalkSeconds <= 0 && record.dueDateAvailableTalkSeconds > 0">当前不适合沟通</p>
      <p v-else-if="record.dueDateAvailableTalkSeconds <= 0" class="redColor">已逾期</p>
      <p v-else>{{ formatTime(record.lastAvailableTalkSeconds) }}</p>
    </div>
    <span slot="studentName" slot-scope="name, record">
      <a-tooltip placement="top">
        <template slot="title">
          <span v-if="name">
            {{ name }}
            <span v-if="record.processVariables.studentCode">（{{ record.processVariables.studentCode }}）</span>
          </span>
          <span v-else>-</span>
        </template>
        <span v-if="name">
          {{ name
          }}<span v-if="record.processVariables.studentCode">（{{ record.processVariables.studentCode }}）</span>
        </span>
        <span v-else>-</span>
      </a-tooltip>
      <!-- <span v-if="name"> {{ name }}<span v-if="record.processVariables.studentCode">（{{ record.processVariables.studentCode }}）</span> </span>
      <span v-else>-</span> -->
    </span>
    <div slot="taskName" slot-scope="taskName, record">
      <a-tag color="green" v-if="hasSubscribeStu(record)">订阅</a-tag>
      <span>{{ (record.processVariables.depthService || '') + record.name }}</span>
    </div>

    <span slot="studyStartTime" slot-scope="time, record">{{ initStudyStartTime(record) }}</span>
    <span slot="promote" slot-scope="promote">{{
      `${promote === 'PURCHASED' ? '已购买补升' : '已核实该学员放弃补升'}`
    }}</span>
    <span slot="finished" :style="{ color: initStatus(record).statusColor }" slot-scope="finished, record">{{
      initStatus(record).taskStatus
    }}</span>
    <span slot="finishedCopy" :style="{ color: initStatus(record).statusColor }" slot-scope="finished, record">{{
      initStatus(record).taskStatus
    }}</span>
    <span slot="taskType" slot-scope="taskType">{{ initOptionsType(taskType, optionsType.courseType) }}</span>
    <span slot="startUserRole" slot-scope="startUserRole">{{
      initOptionsType(startUserRole, optionsType.userRole)
    }}</span>
    <span slot="selfdescription">请完成该学生的【离职交接】跟进记录，并确保【关闭】该生相关的已完成任务</span>
    <span slot="studentNameCode" slot-scope="student, record"
      >{{ record.processVariables.studentName
      }}<span v-if="record.processVariables.studentCode">（{{ record.processVariables.studentCode }}）</span></span
    >
    <span slot="absentClass" slot-scope="student, record"
      >{{ record.processVariables.schedule.scheduleName }}（{{ record.processVariables.schedule.teacherName }}）</span
    >

    <span slot="absentDate" slot-scope="student, record"
      >{{ record.processVariables.schedule.startDateTime }}-{{
        record.processVariables.schedule.endDateTime && record.processVariables.schedule.endDateTime.substring(10)
      }}</span
    >
    <!--待办里面才有操作,只有当前节点负责人==花果当前登录用户，才显示-->
    <span slot="action" class="action-wrap" slot-scope="text, record" v-if="record.assignee === userInfo.userID">
      TEACHER_TAKE_OVER_FOLLOW
    </span>
  </a-table>
</template>
<script>
import storage from 'store';
import OfflineComplete from './OfflineComplete.vue';
import { getLabelFromOptions } from '@/utils/geoLocation';

export default {
  components: { OfflineComplete },
  data() {
    return {
      selectedRowKeys: [], // 表格里面得多选操作
      userInfo: {},
      offlinefinishLoading: '',
      expandedRowKeys: [],
    };
  },
  props: {
    tableData: Array,
    loading: Boolean,
    columns: Array,
    showRow: Boolean,
    optionsType: Object,
    activeName: String,
    pagination: Object,
    countryOptions: Array,
    onFinishUploadTask: {
      type: Function,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    hasSubscribeStu(record) {
      console.log(record.processVariables.allStudentInfoList);
      if (!record.processVariables.allStudentInfoList) return false;
      return record.processVariables.allStudentInfoList.some((item) => item.subscribe);
    },
    getLabelFromOptions,
    formatTime(seconds) {
      const SECONDS_IN_A_MINUTE = 60;
      const SECONDS_IN_AN_HOUR = 3600;
      const SECONDS_IN_A_DAY = 86400;

      if (seconds >= SECONDS_IN_A_DAY) {
        const days = Math.floor(seconds / SECONDS_IN_A_DAY);
        const remainingSeconds = seconds % SECONDS_IN_A_DAY;
        const hours = Math.floor(remainingSeconds / SECONDS_IN_AN_HOUR);
        return `剩余${days}天${hours}时`;
      } else {
        const hours = Math.floor(seconds / SECONDS_IN_AN_HOUR);
        const remainingSeconds = seconds % SECONDS_IN_AN_HOUR;
        const minutes = Math.floor(remainingSeconds / SECONDS_IN_A_MINUTE);
        return `剩余${hours}时${minutes}分`;
      }
    },
    formatSeconds(seconds) {
      // 计算小时数
      const hours = Math.floor(seconds / 3600);
      // 计算剩余的分钟数
      const minutes = Math.floor((seconds % 3600) / 60);

      // 将小时、分钟和秒数格式化为两位数
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      // 返回格式化后的时间字符串
      return `${formattedHours}时${formattedMinutes}分`;
    },
    getUploadFields(record, key, emptyValue) {
      if (!record?.processVariables?.offLineUpLoadDataDto?.[key]) return emptyValue;
      const html = record?.processVariables?.offLineUpLoadDataDto?.[key].replaceAll(
        /(https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#]))?/g,
        '<a href="$1" target="_blank">$1</a>',
      );
      return html;
    },
    handleExpand(value, record) {
      if (record.type !== 'OFFLINE_UPLOAD') {
        return;
      }

      const { id } = record;
      if (value) {
        this.expandedRowKeys.push(id);
      } else {
        this.expandedRowKeys = this.expandedRowKeys.filter((item) => item !== id);
      }
    },
    initStudyStartTime(record) {
      let time;
      const startTime = record.processVariables.lastSchedule.startDateTime;
      const endTime = record.processVariables.lastSchedule.endDateTime.substring(11, 16);
      const nowYear = this.$moment().year();
      const dataYear = this.$moment(time).year();
      if (nowYear === dataYear) {
        time = `${this.$moment(startTime).format('MM月DD日 HH:mm')}-${endTime}`;
      } else {
        time = `${this.$moment(startTime).format('YYYY年MM月DD日 HH:mm')}-${endTime}`;
      }
      return time;
    },

    handleTableChange(pagination, filters, sorter) {
      this.$emit('handleTableChange', pagination, filters, sorter);
    },
    // 转换状态
    initStatus(record) {
      if (record.businessTaskStatus === 'SYSTEM_CANCEL') {
        record.taskStatus = '系统已取消';
        record.statusColor = '#333';
        return record;
      }
      if (record.businessTaskStatus === 'CANCEL') {
        record.taskStatus = '已取消';
        record.statusColor = '#333';
        return record;
      }

      if (record.processFinished) {
        if (record.processDeleted) {
          record.taskStatus = '已拒绝';
          record.statusColor = 'red';
        } else {
          record.taskStatus = '已完成';
          record.statusColor = '#333';
        }
      } else {
        record.taskStatus = '待处理';
        record.statusColor = 'orange';
      }
      return record;
    },
    // 多选操作
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit('selectChange', selectedRowKeys, selectedRows);
    },
    handleOperate(type, record) {
      this.$emit('handleOperate', type, record);
    },

    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },
  },
  mounted() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;
  },
};
</script>

<style lang="less" scoped>
.action-wrap {
  a {
    margin-right: 10px;
    color: #3372fe;
  }
}
.redColor {
  color: #ff5353;
}
.greenColor {
  color: #27d9a0;
}

.offline-upload-wrapper {
  display: flex;
  padding: 16px;
  .upload-info {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .upload-item {
      padding: 0 16px;
      margin-bottom: 24px;
      width: 50%;
      border-right: 1px dashed #e8e8e8;
      display: flex;
      flex-direction: column;
      position: relative;
      .info-row {
        display: flex;
        flex: 1;
        .label {
          font-weight: 600;
          white-space: nowrap;
        }
        .value {
          white-space: pre-wrap;
        }
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #04cb94;
        position: absolute;
        left: 12px;
        top: 7px;
      }
    }
  }
  .upload-operation {
    width: 100px;
    display: flex;
    justify-content: center;
  }
}
</style>
