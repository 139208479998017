<template>
<div>
   <a-modal
      title="补升超期提醒"
      :visible=true
      @ok="onMakeUpOverdue"
      @cancel="cancelMakeUpOverdue"
      okText="立刻前往"
      cancelText="稍后前往"
    >
      <p>
        学生<span class="blueColor">
          {{ makeUpOverdue.processVariables.studentName }}（{{ makeUpOverdue.processVariables.studentCode }}）</span
        >已超过补升有效期，需提交【订单审批】补升超期审批后， 可前去补升。
      </p>
    </a-modal>
</div>
  
</template>

<script>
export default {
  props:{
    makeUpOverdue:{
      type:Object
    }
  },
  data(){
    return{

    }
  },
  methods:{
    onMakeUpOverdue(){
      this.$emit("onMakeUpOverdue")
    },
    cancelMakeUpOverdue(){
      this.$emit('cancelMakeUpOverdue')
    }
  }
}
</script>

<style lang="less" scoped>
.blueColor{
  color:#43D186;
}
</style>