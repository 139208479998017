<template>
  <a-list :data-source="taskData">
    <a-list-item slot="renderItem" slot-scope="item">
      <div class="task-item" @click="selTaskItem(item, 'nofinish')">
        <div class="task-left"></div>
        <div class="task-right" :class="{ active: taskInfoData.subjectType === item.subjectType }">
          <span
            class="icon-item"
            :class="['icon-item' + item.type, { activeIcon: taskInfoData.subjectType === item.subjectType }]"
          ></span>
          <span>{{ item.name }}</span>

          <span class="task-num" v-if="delayshow">
            <span class="delay-txt" v-if="item.overtime">逾期</span>
            {{ item.num }}</span
          >
          <span class="arrow-icon"></span>
        </div>
      </div>
    </a-list-item>
  </a-list>
</template>
<script>
export default {
  data() {
    return {
      taskInfoData: {},
    };
  },
  props: {
    taskData: Array,
    delayshow: Boolean,
    firstFlag: Boolean, //第一次进来才默认选中
  },
  computed: {},
  methods: {
    selTaskItem(item) {
      this.taskInfoData = item;
      this.$emit('select', this.taskInfoData);
    },
  },
  watch: {
    taskData(newVal) {
      console.log(newVal);
      if (newVal.length > 0 && this.firstFlag) {
        this.taskInfoData = newVal[0];
        this.$emit('select', this.taskInfoData);
      }
    },
  },
  // mounted() {
  //   this.taskInfoData = this.taskData[0];
  //   this.$emit('select', this.taskInfoData);
  // },
};
</script>

<style lang="less" scoped>
.task-list {
  margin-top: 20px;
}
.task-item {
  display: flex;
  cursor: pointer;
}
.task-left {
}
.task-right {
  background: rgba(179, 179, 179, 0.06);
  padding: 13px 12px;
  border-radius: 10px;
  width:380px;
  display: flex;
  font-size: 16px;
  align-items: center;
  .task-num {
    flex: 1;
    text-align: right;
    color: #999;
  }
  .delay-txt {
    color: #ff5353;
    padding-right: 5px;
  }
  .icon-item {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
  .icon-itemTASK_CREATE_GROUP,
  .icon-itemTASK_CLASS_ADMIN_JOIN,.icon-itemTASK_CLASS_ADMIN_RESIGN_STUDENT {
    background: url('~@/assets/newgroup.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/newgroup_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_FIRST_PASS,
  .icon-itemTASK_CLASS_ADMIN_FIRST_PASS {
    background: url('~@/assets/phonecall.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/phonecall_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_RESIGN_STUDENT_NEW_CLASSADMIN,
  .icon-itemTASK_RESIGN_STUDENT_OLD_CLASSADMIN{
    background: url('~@/assets/people.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/people_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //排课相关
  .icon-itemTASK_SCHEDULE,
  .icon-itemSCHEDULE_RELATED {
    background: url('~@/assets/arrangeclass.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/arrangeclass_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //课节进度
  .icon-itemLESSON_PROGRESS_RELATED {
    background: url('~@/assets/adjust.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/adjust_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //重复线索
    //课节进度
  .icon-itemTASK_REPEAT_TRACK,.icon-itemTAKE_OVER{
    background: url('~@/assets/todo.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/todo_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //学生相关
  .icon-itemSTUDENT_RELATED {
    background: url('~@/assets/student.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/student_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //老师请假
  .icon-itemCHINESE_TEACHER_LEAVE,.icon-itemAPPLICATION_FOR_TEACHER_LEAVE{
    background: url('~@/assets/vacate.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/vacate_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //离职班级交接
  .icon-itemCHINESE_RESIGN_TAKE_OVER {
    background: url('~@/assets/dimission.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/dimission_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //学习报告
  .icon-itemSTAGE_LEARNING_REPORT{
    background: url('~@/assets/report.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/report_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  //长期班级交接申请
  // .icon-itemTAKE_OVER {
  //   background: url('~@/assets/handover.png') no-repeat center;
  //   background-size: 100%;
  //   &.activeIcon {
  //     background: url('~@/assets/handover_ed.png') no-repeat center;
  //     background-size: 100%;
  //   }
  // }
  //考勤相关
  .icon-itemATTENDANCE_RELATED {
    background: url('~@/assets/classtime.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/classtime_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_CONFIRM_ORDER {
    background: url('~@/assets/send.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/send_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_PROMOTE{
    background: url('~@/assets/order_makeup.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/order_makeuped.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_FIRST_VISIT,
  .icon-itemTASK_W3_VISIT,
  .icon-itemTASK_W2_VISIT,
  .icon-itemTASK_MOTH_VISIT,
  .icon-itemTASK_CHANGE_TEACHER_VISIT
  {
    background: url('~@/assets/recall.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/recall_ed.png') no-repeat center;
      background-size: 100%;
    }
  }

  &.active {
    background-color:#43D186;
    color: #fff;
    .task-num {
      color: #fff;
    }
    .arrow-icon {
      background: url('~@/assets/home_icon_enter_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
}
.icon-itemTASK_CLASS_ADMIN_RESIGN_TAKE_OVER{
    background: url('~@/assets/resign.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/resign_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_STUDENT_SHARE_AUDIT,.icon-itemPROMOTE_EXPIRED_TASK,.icon-itemSUBMIT_ORDER_TASK{
    background: url('~@/assets/approve.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/approve_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
  .icon-itemTASK_CERTIFICATE{
    background: url('~@/assets/certificate.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/certificate_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
.icon-itemTASK_STUDENT_ABSENT,.icon-itemTASK_TEACHER_ABSENT{
    background: url('~@/assets/studentabsent.png') no-repeat center;
    background-size: 100%;
    &.activeIcon {
      background: url('~@/assets/studentabsent_ed.png') no-repeat center;
      background-size: 100%;
    }
  }
.task-delaytxt {
  color: red;
}
.task-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}
.ant-list-split .ant-list-item {
  border-bottom: none;
}
.ant-list-item {
  padding: 12px 0 0 0;
}
</style>
